import React, { Component } from "react";
import Paragraph from "../Paragraph/Paragraph";
import Include from "../../../../drupal/Include";

export default class TwoColumnSection extends Component {

    render = () => {
        const id = this.props.item.get('id');

        const first = this.props.item.includes.filter((element) => {
            return (element?.attributes?.behavior_settings?.layout_paragraphs?.parent_uuid === id && element?.attributes?.behavior_settings?.layout_paragraphs?.region === 'first')
        });

        let firstContent = [];
        for(const paragraph of first) {
            const include = new Include(paragraph, this.props.item.includes);
            firstContent.push(<Paragraph key={include.get('id')} content={include} />);
        }

        const second = this.props.item.includes.filter((element) => {
            return (element?.attributes?.behavior_settings?.layout_paragraphs?.parent_uuid === id && element?.attributes?.behavior_settings?.layout_paragraphs?.region === 'second')
        });
        let secondContent = [];
        for(const paragraph of second) {
            const include = new Include(paragraph, this.props.item.includes);
            secondContent.push(<Paragraph key={include.id} content={include} />);
        }

        return (
            <div className="two-column-section page-section padded">
                <div className="row">
                    <div className="col">
                        {firstContent}
                    </div>
                    <div className="col">
                        {secondContent}
                    </div>
                </div>
            </div>
        )
    }

}