import React, { Component } from 'react';
import { Image as Img } from 'react-bootstrap';

import './Image.css';

export default class Image extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let caption = null;
    if(this.props.caption) {
        caption = (
            <div className="caption">
                {this.props.caption}
            </div>
        );
    }

    return (
        <div className="paragraph image page-section padded">
            {title}
            {subtitle}
            <Img src={this.props.media.get('field_media_image').get('links').extra_large.href} />
            {caption}
        </div>
    );
  }

}